<template>
    <div class="trend">
        <div class="trend_content">
            <div class="container-water-fall">
                <waterfall :col='col'
                    :width="itemWidth"
                    :gutterWidth="gutterWidth"
                    :data="data"
                >
                    <template>
                        <div class="cell-item box-shadow p-r" v-for="(item,index) in data" :key="index" @click="openDetail(item)">
                            <img :src="item.thumb" alt="加载错误" />
                            <div class="item-descA padding-0-5 l-h-25">{{item.title}}</div>
                            <div v-if="item.zhiding" class="flex flex-w-n flex-ai-c label-item m-t-5 m-b-5 p-a opacity-3" style="top: 1px;left: 2px;padding: 2px 0;">
                                <div class="flex flex-ai-c m-r-10 zhiding">
                                    <van-icon name="back-top" /><span>置顶</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </waterfall>
            </div>
        </div>
    </div>
</template>

<script>
    
	export default {
		props:{
			data:{
				type:Array
			}
		},
		data() {
			return {
                col: 2
			}
		},
        computed:{
            itemWidth(){
                //#rem布局 
                let spacing = 10
                if(this.IsPC()) spacing = 20
                
                return ((document.documentElement.clientWidth*0.5)-parseFloat(spacing))
            },
            gutterWidth(){
                //#rem布局 计算x轴方向margin(y轴方向的margin自定义在css中即可)
                // return (16*0.5*(document.documentElement.clientWidth/375))
                return 10
            }
        },
        mounted() {
            
        },
        methods:{
            openDetail(item){
                if(item.link){
                    window.location.href = item.link
                }else{
                    this.$router.push({
                        path:'/article/details',
                        query:{
                            id:item.id
                        }
                    })
                }
            },
            
            IsPC() { // 判断是否是pc端  true为pc端
                let userAgentInfo = navigator.userAgent;
                let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
                let flag = true;
                for (let v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        flag = false;
                        break;
                    }
                }
                return flag;
            }
        }
	}
</script>

<style>
    .label-item span{
        font-size: 10px;
        margin-left: 2px;
    }
    .label-item .zhiding{
        color: #FF6600;
    }
    
    /* 瀑布流 */
    .cell-item img {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
    }

    .container-water-fall {
        padding: 10px 5px;
        box-sizing: border-box;
    }
    .cell-item {
        overflow: hidden;
        box-sizing: border-box;
        margin-bottom: 15px;
        background-color: #ffffff;
    }
    .item-descA {
        font-size: 15px;
        color:rgba(46,44,42,1);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp:1;
        margin: 5px 0;
    }
    .item-descB {
        font-size:15px;
        font-weight:500;
        color:rgba(89,89,89,1);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp:1;
        margin-bottom: 5px;
    }
    .item-footer {
        margin-top: 22px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .tech-list-ll .tab-bottom{
        padding:10px 0 !important;
        height: 0 !important;
        background: #fafafa !important;
    }
    .spans span{
        color: rgb(220, 0, 27);
        font-size: 14px;
    }
    .ems{
        text-decoration: line-through;
    }
    .tabbar_give{
        background-color: rgb(252, 222, 211);
        padding: 3px;
        color: #DF8E6C;
    }
</style>
