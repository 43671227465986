<template>
	<div>
		<van-nav-bar
			title="所有图文"
			left-text="返回"
			left-arrow
            fixed
			@click-left="onClickLeft"
		/>
		<div style="height: 50px;"></div>
        
		<waterfall-list :data="waterfallData"></waterfall-list>
	</div>
</template>

<script>
	import waterfallList from '../components/list/waterfall.vue'
	
	export default {
		components:{
			waterfallList
		},
		data() {
			return {
				waterfallData:[]
			}
		},
        mounted() {
            this.$api.index.getWaterfall().then((res) => {
                this.waterfallData = res.data
            })
        },
		methods:{
			onClickLeft() {
				// this.$toast('返回');
				this.$router.go(-1);
			},
			onClickRight() {
				this.$toast('按钮');
			},
		}
	}
</script>

<style>
</style>
